<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right; display: flex">
        <div>
          <el-button size="small" @click="downXls_jiuye" icon="el-icon-download"
            >下载学生毕业信息（就业）</el-button
          >
          <el-button size="small" @click="downFenlei" icon="el-icon-download"
            >下载学生毕业信息（升学）</el-button
          >
          <el-button size="small" @click="downMingdan" icon="el-icon-download"
            >下载学生毕业信息（未就业）</el-button
          >
          <el-button size="small" @click="downZhongzhi" icon="el-icon-download"
            >下载中职就业信息</el-button
          >
        </div>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生数据</el-breadcrumb-item>
          <el-breadcrumb-item>国平台表格</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <el-table
        v-tableFit
        border
        stripe
        :data="DataList"
        style="width: 100%; margin-top: 20px; color: #000"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="xm" label="姓名" width="120"></el-table-column>
        <el-table-column prop="bynf" label="毕业年月" width="120"></el-table-column>
        <el-table-column prop="byqx" label="毕业去向" min-width="120"></el-table-column>
        <el-table-column prop="jydw" label="就业单位" min-width="200"></el-table-column>
        <el-table-column prop="jnjw" label="境内/境外" width="100"></el-table-column>
        <el-table-column prop="jygj" label="就业国家/地区" width="200"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  name: "guopingtai",
  data() {
    return {
      DataList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.post("/api/szz_xls_down_jiuye", {}).then((res) => {
        this.DataList = res.data;
      });
    },
    downXls_jiuye() {
      this.$http.post("/api/szz_xls_down_jiuye", { export: 1 }).then((res) => {
        if (res.data.url) {
          window.open(res.data.url, "_blank");
        }
      });
    },
    downFenlei() {
      this.$http.post("/api/szz_xls_down_shengxue", { export: 1 }).then((res) => {
        if (res.data.url) {
          window.open(res.data.url, "_blank");
        }
      });
    },
    downMingdan() {
      this.$http.post("/api/szz_xls_down_weijiuye", { export: 1 }).then((res) => {
        if (res.data.url) {
          window.open(res.data.url, "_blank");
        }
      });
    },
    downZhongzhi() {
      this.$http.post("/api/szz_xls_down_zhongzhi", { export: 1 }).then((res) => {
        if (res.data.url) {
          window.open(res.data.url, "_blank");
        }
      });
    },
  },
};
</script>
<style type="text/less"></style>
